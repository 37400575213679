.containerScroll {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  height: fit-content;
  overflow: hidden;
}

.containerScrollMobile {
  position: relative;
  overflow: hidden;
  height: fit-content;
  @media screen and (min-width: 833px) {
    display: none;
  }
}

.containerScrollTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  position: relative;

  height: fit-content;
  overflow: hidden;
}

.containerTablet {
  position: sticky;
  top: 100px;
  background-image: url(../../images/team/tablet/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 3070px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #111111;
  overflow: hidden;
}

.containerTabletEn {
  position: sticky;
  top: 100px;
  background-image: url(../../images/team/tablet/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 3020px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #111111;
  overflow: hidden;
}

.container {
  position: sticky;
  top: 100px;
  background-image: url(../../images/team/Our_team_Section_BG_01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 3320px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #111111;
  overflow: hidden;
}

.containerEn {
  position: sticky;
  top: 100px;
  background-image: url(../../images/team//Our_team_Section_BG_01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 3230px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #111111;
  overflow: hidden;
}

.containerMobile {
  position: sticky;
  background-image: url(../../images/team/mobile/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 5100px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #111111;
  overflow: hidden;
}
.containerMobileEn {
  position: sticky;
  background-image: url(../../images/team/mobile/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 5000px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #111111;
  overflow: hidden;
}
.wrapper {
  position: absolute;
  top: 60px;
  left: calc(50% - 160px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    position: absolute;

    left: calc(50% - 450px);
    width: 900px;
  }

  @media screen and (min-width: 1300px) {
    position: absolute;
    top: 80px;
    left: calc(50% - 550px);
    width: 1100px;
  }
}
.wrapperHistory {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 1300px) {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
  }
}

.historyLeftPart {
  text-align: center;
  width: 100%;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 377px;
    margin-top: 30px;
  }

  @media screen and (min-width: 1300px) {
    width: 514px;
  }
}

.historyLeftPartTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }

  width: 377px;
  margin: 0 auto;
  margin-top: 150px;
}

.historyLeftPartMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  margin: 0 auto;
  width: 234px;
  margin-top: 100px;
}
.historyLeftPartDesktop {
  @media screen and (max-width: 1299px) {
    display: none;
  }

  width: 514px;
  margin: 0 auto;
  margin-top: 200px;
}

.teamLeftPart {
  @media screen and (max-width: 833px) {
    margin-top: 50px;
  }
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 24px;
    width: 350px;
  }

  @media screen and (min-width: 1300px) {
    margin-top: 24px;
    width: 514px;
  }
}

.teamRightPart {
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 350px;
  }

  @media screen and (min-width: 1300px) {
    width: 514px;
  }
}
.teamRightPartDe {
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 350px;
  }

  @media screen and (min-width: 1300px) {
    width: 514px;
  }
}

.h3 {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  @media screen and (max-width: 834px) {
    margin-left: 15px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;
  }
}
.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    height: 640px;
    padding-top: 30px;
    padding-left: 67px;
    padding-right: 67px;

    background: rgba(29, 29, 29, 0.2);
    backdrop-filter: blur(24px);
    font-family: Titillium Web;
    font-size: 22px;
    line-height: 1.5;
    color: #fbfbfb;
  }
}
.closeIcon {
  cursor: pointer;
  position: fixed;
  top: 120px;
  right: 30px;
  z-index: 10000;
  fill: #111111;
  @media screen and (max-width: 833px) {
    top: 70px;
    right: 10px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 70px;
    right: 15px;
  }
}
.textEn {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    height: 540px;
    padding-top: 30px;
    padding-left: 67px;
    padding-right: 67px;

    background: rgba(29, 29, 29, 0.2);
    backdrop-filter: blur(24px);
    font-family: Titillium Web;
    font-size: 22px;
    line-height: 1.5;
    color: #fbfbfb;
  }
}

.textAccent {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  @media screen and (min-width: 833px) {
    text-align: justify;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    height: 295px;
    margin-top: -60px;
    padding-top: 90px;
    padding-left: 60px;
    padding-right: 60px;

    background: rgba(29, 29, 29, 0.2);
    backdrop-filter: blur(24px);
    font-family: Titillium Web;
    font-size: 22px;
    line-height: 1.5;
    color: #fbfbfb;
  }
}

.images {
  border-radius: 20px;
  margin-top: 27px;
}
.imgWrapper {
  position: relative;
}
.pictures {
  margin-bottom: 27px;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1300px) {
    margin-bottom: 27px;
    padding-left: 67px;
    padding-right: 0;
  }
}
.historyRightPart {
  width: 331px;
  @media screen and (max-width: 833px) {
    margin-top: 30px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 377px;
    margin-top: 30px;
  }

  @media screen and (min-width: 1300px) {
    box-sizing: border-box;
    width: 550px;
    padding: 60px;
    padding-bottom: 0;
    padding-top: 0;
  }
}
.containerBottomDesktop {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.textLine {
  @media screen and (max-width: 833px) {
    margin-bottom: 10px;
    text-align: justify;
  }
  margin-bottom: 10px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #ffffff;

  @media screen and (min-width: 1300px) {
    line-height: 20px;

    margin-bottom: 20px;
  }
}
.textLineAccent {
  @media screen and (max-width: 833px) {
    margin-bottom: 10px;
    text-align: justify;
  }
  margin-bottom: 10px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #ffffff;

  @media screen and (min-width: 1300px) {
    line-height: 20px;

    margin-bottom: 20px;
  }
}
.textLineTop {
  @media screen and (max-width: 833px) {
    text-align: justify;
  }

  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;

  @media screen and (min-width: 1300px) {
    line-height: 20px;
  }
}
.textLineUppercase {
  text-transform: uppercase;
  @media screen and (max-width: 833px) {
    margin-bottom: 10px;
    text-align: justify;
  }
  margin-bottom: 10px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #ffffff;

  @media screen and (min-width: 1300px) {
    line-height: 20px;

    margin-bottom: 20px;
  }
}
.textLineBackdrop {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;

  text-align: justify;
  margin-bottom: 30px;
  @media screen and (max-width: 833px) {
    width: 100%;

    height: fit-content;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 145px;
    width: 400px;
  }
  @media screen and (min-width: 1300px) {
    width: 500px;
    margin-top: 200px;
  }
}
.backdropWrapper {
  @media screen and (max-width: 833px) {
    width: calc(100vw - 40px);

    padding-right: 20px;
    padding-left: 20px;
  }
  overflow-y: auto;

  height: 100%;

  @media screen and (min-width: 834px) {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (min-width: 1300px) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.backdropWrapper::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.backdropWrapper::-webkit-scrollbar-thumb {
  background-color: #3b3b3b;
  border-radius: 5px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 501;
  width: 100vw;
  height: 100vh;
  background: rgba(29, 29, 29, 0.2);
  backdrop-filter: blur(24px);
}
.information {
  cursor: pointer;
  @media screen and (max-width: 833px) {
    margin-bottom: 10px;
    text-align: justify;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: rgb(232, 160, 255);

  @media screen and (min-width: 1300px) {
    line-height: 20px;

    margin-bottom: 20px;
  }
}

.textLineAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.textLineUppercaseAccent {
  text-transform: uppercase;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.imgText {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    padding-left: 67px;
    padding-right: 67px;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;

    text-shadow: 2px 2px 3px rgba(29, 29, 29, 0.7);
  }
}
.h4 {
  margin-bottom: 30px;
  font-family: Titillium Web;
  font-size: 22px;
  font-weight: 500;
  color: var(--accent_color);
}
.team {
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    justify-content: space-between;
    align-items: center;
    width: 900px;
  }

  @media screen and (min-width: 1300px) {
    justify-content: space-between;
    align-items: center;
    width: 1100px;
  }
}
.linkList {
  margin-top: 35px;
  margin-bottom: 30px;

  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 600px;
  }

  @media screen and (min-width: 1300px) {
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 740px;
    align-items: flex-start;
  }
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: calc(900px / 3);
  }

  @media screen and (min-width: 1300px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    width: calc(1100px / 3);
  }
}

.company {
  text-align: center;
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #fbfbfb;
}

.resposibility2 {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 15px;

  @media screen and (min-width: 1300px) {
    margin-left: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
  }
}
.resposibility {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #ffffff;
  margin-left: 15px;

  @media screen and (min-width: 1300px) {
    margin-top: 13px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
  }
}
.mail {
  margin-top: 10px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 15px;
  transition: 200ms;

  @media screen and (min-width: 1300px) {
    margin-left: 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
}
.position {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #ffffff;
}

.containerPerson {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  padding-left: 17px;
  padding-bottom: 14px;
  border-left: 3px solid #781d95;
  border-bottom: 3px solid #781d95;
}
.fio {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 100px;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    font-size: 22px;
    line-height: 24px;
  }
}

.arrowButton {
  margin: 0 auto;
  display: block;
  width: 190px;
  background-color: transparent;
  border: transparent;
  cursor: pointer;
}

.iconArrow {
  fill: #fcfcfc;

  transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  opacity: 0;
  transition-delay: 2s;
  -webkit-animation: fadebottom 2s ease-in-out 2s infinite;

  @keyframes fadebottom {
    0% {
      opacity: 1;
      -webkit-transform: translate(0, 35px);
    }
  }
}
.iconArrow:hover,
.iconArrow:focus {
  transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  fill: currentColor;
  color: var(--accent_color);
}
.img {
  margin: 0 auto;
}

.teamManagement {
  @media screen and (max-width: 833px) {
    left: calc(50% - 175px);
    margin-bottom: 83px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 45px;
  }
  position: relative;
  margin-bottom: 45px;
}

.teamLink {
  position: absolute;
  top: 0;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: -120px;
  }

  @media screen and (min-width: 1300px) {
    top: -120px;
  }
}
.teamLinkAccent {
  position: absolute;
  top: -60px;
}

.containerWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  @media screen and (max-width: 833px) {
    margin-bottom: 30px;
    margin-left: -10px;
  }

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    position: relative;
    margin-left: calc(50% - 250px);
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1300px) {
    position: relative;
    margin-left: calc(50% - 300px);
    display: flex;
    align-items: center;
  }
}

.linkHistory {
  position: absolute;
  top: -100px;
}
.line {
  margin-top: 400px;
  border-top: 2px solid #781d95;
}

.lineAccent {
  margin-top: 20px;
  border-top: 2px solid #781d95;
}
.lineEn {
  margin-top: 323px;
  border-top: 2px solid #781d95;
}

.lineAccentEn {
  margin-top: 20px;
  border-top: 2px solid #781d95;
}
.picturesHistory {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    padding-left: 80px;
  }
}

.containerWrapperHistory {
  @media screen and (max-width: 833px) {
    left: -20px;
    margin-bottom: 20px;
  }
  margin-top: -40px;
  position: relative;
  z-index: 2;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: -45px;
    position: relative;
    z-index: 2;
    left: -45px;
  }

  @media screen and (min-width: 1300px) {
    position: relative;
    z-index: 2;
    left: -60px;
    top: -20px;
  }
}

.imgTextAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 33px;
    line-height: 22px;
  }
}

.containerPosition {
  @media screen and (min-width: 834px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.containerSubTitle {
  margin-top: 235px;
  margin-bottom: 37px;
}

.imgSubText {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 22px;

  text-transform: uppercase;
  color: #ffffff;
}
.imgSub {
  border-radius: 20px;
}

.wrapperTextTablet {
  margin-top: 24px;
}

.containerWrapperHistoryDesktop {
  background: rgba(29, 29, 29, 0.2);
  backdrop-filter: blur(24px);
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
  margin-top: 40px;
}

.containerWrapperHistoryTablet {
  padding: 20px;
  background: rgba(29, 29, 29, 0.2);
  backdrop-filter: blur(24px);
  box-sizing: border-box;
}
.wrapperText {
  padding: 20px;
  padding-top: 0;
}

.linkSocial:hover .mail {
  color: #e8a0ff;
}
